export const MORTGAGES_PATH = "mortgages";

export const MORTGAGE_TYPE_FILTERING_KEY = "mortgageType";
export const CREDIT_SCORE_FILTERING_KEY = "creditQuality";
export const LOAN_AMOUNT_FILTERING_KEY = "loanAmountServiced";
export const MORTGAGE_PURCHASE_URL_PARAMETER = "mortgage-purchase";
export const MORTGAGE_REFINANCE_URL_PARAMETER = "mortgage-refinance";

export const RC_SITE_CONFIGURATION = {
	[MORTGAGES_PATH]: {
		preventRenderingCompanyListHeader: true,
		showFeaturedCompany: true,
	},
};
