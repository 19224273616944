import { useContext } from "react";
import { convertRatingToStars } from "@/helpers";
import PropTypes from "prop-types";
import { includes } from "ramda";
import { useSelector } from "react-redux";

import { TrustPilotStarIcon } from "../../client/assets/icons/common/TrustPilorStarIcon";
import { TrustPilotLogo } from "../../client/assets/icons/common/TrustPilotLogo";
import { getTrustpilotReviewCount, getTrustpilotScore } from "../../selectors";
import { CompanyContext } from "../CompanyList/CompanyContext";

const TO_TRIM = "Half";

const defaultClassNames = {
	star: "tw-z-1",
	halfStar:
		"tw-absolute tw-left-1/2 tw-top-0 tw-w-1/2 tw-h-full tw-rounded-r tw-bg-[#d9d9d9]",
	default: "tw-bg-[#d9d9d9]",
	excellent: "tw-bg-[#00B679]",
	good: "tw-bg-[#98C957]",
	fair: "tw-bg-[#f18b3f]",
	average: "tw-bg-[#f9ce47]",
	poor: "tw-bg-[#ec4b34]",
};

export const BaseTrustpilotReview = ({ classNames = {} }) => {
	const mergedClassNames = { ...defaultClassNames, ...classNames };
	const { company } = useContext(CompanyContext);

	const reviewCount = useSelector((state) =>
		getTrustpilotReviewCount(state, { company }),
	);
	const reviewScore = useSelector((state) =>
		getTrustpilotScore(state, { company }),
	);

	if (!reviewCount || reviewCount === 0 || !reviewScore)
		return (
			<div className={mergedClassNames.container}>
				<div className={mergedClassNames.reviewCount}>No Reviews Yet</div>
				<div className={mergedClassNames.ratingCountWrapper}>
					<TrustPilotLogo className={mergedClassNames.trustPilotLogo} />
				</div>
			</div>
		);

	const textualRating = convertRatingToStars(reviewScore);

	const ratingStars = textualRating.map((quality, index) => {
		const halfStarBool = includes(TO_TRIM, quality);

		if (halfStarBool) {
			const qualityTrimmed = quality.substring(
				0,
				quality.length - TO_TRIM.length,
			);

			return (
				<div
					key={index}
					className={`${mergedClassNames.starBase} ${mergedClassNames[qualityTrimmed]}`}
				>
					<span className={mergedClassNames.halfStar}></span>
					<TrustPilotStarIcon className={mergedClassNames.star} />
				</div>
			);
		}

		return (
			<div
				key={index}
				className={`${mergedClassNames.starBase} ${mergedClassNames[quality]}`}
			>
				<TrustPilotStarIcon className={mergedClassNames.star} />
			</div>
		);
	});

	return (
		<div className={mergedClassNames.container}>
			<div className={mergedClassNames.reviewCount}>
				<span>{reviewCount.toLocaleString()}</span> Reviews
			</div>
			<div className={mergedClassNames.ratingContainer}>{ratingStars}</div>
			<div className={mergedClassNames.ratingCountWrapper}>
				<span className={mergedClassNames.ratingCount}>
					{reviewScore.toFixed(1)}
				</span>
				<span className={mergedClassNames.ratingCountText}>on</span>
				<TrustPilotLogo className={mergedClassNames.trustPilotLogo} />
			</div>
		</div>
	);
};

BaseTrustpilotReview.propTypes = {
	classNames: PropTypes.object,
};
