import { SITE, TCR_DOMAIN } from "@/config/constants";
import * as tcrConstants from "@/config/site/thecreditreview";
import * as selectors from "@/selectors";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useSelector } from "react-redux";

const styles = {
	baseStyles: css`
		display: flex;
		width: 350px;
		background-color: #fff;
		height: 40px;

		:last-child {
			margin-right: 0px;
		}
	`,
	domainLevelOverrides: {
		[TCR_DOMAIN]: {
			allVerticals: css`
				margin-right: 15px;

				@media (max-width: 767px) {
					width: 100%;
				}
			`,
			[tcrConstants.AUTO_REFINANCE_PATH]: css`
				@media (max-width: 1199px) {
					width: 345px;
				}

				@media (max-width: 767px) {
					width: 100%;
					margin: 0 0 10px;
				}
			`,
			[tcrConstants.BUSINESS_LOANS_DEFAULT_PATH]: css`
				@media (max-width: 1199px) {
					width: 345px;
				}

				@media (max-width: 767px) {
					width: 100%;
					margin: 0 0 10px;
				}
			`,
		},
	},
};

const FilterWrapper = (props) => {
	const vertical = useSelector(selectors.getVertical);

	return (
		<div
			css={[
				styles.baseStyles,
				path(["domainLevelOverrides", SITE, "allVerticals"], styles),
				path(["domainLevelOverrides", SITE, vertical], styles),
			]}
		>
			{props.children}
		</div>
	);
};

export default FilterWrapper;

FilterWrapper.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};
