import PropTypes from "prop-types";
import { Radio, RadioGroup } from "react-aria-components";

export const BaseSegmentedToggle = ({
	options,
	selected,
	onChange,
	styles,
	...props
}) => (
	<RadioGroup
		value={selected}
		onChange={onChange}
		className={styles.radioGroup}
		{...props}
	>
		{options.map(({ value, label }) => (
			<Radio
				key={value}
				value={value}
				className={`${styles.radio} ${
					selected === value ? styles.selected : styles.default
				}`}
			>
				{label}
			</Radio>
		))}
	</RadioGroup>
);

BaseSegmentedToggle.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
				.isRequired,
		}),
	).isRequired,
	selected: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	styles: PropTypes.object.isRequired,
};
