import { useContext } from "react";
import PropTypes from "prop-types";

import { CompanyContext } from "../CompanyList/CompanyContext";
import BaseAwardText from "./BaseAwardText";

export const BaseServiceFlag = ({ style, classNames, icon }) => {
	const { companyIndex } = useContext(CompanyContext);

	return (
		<div css={style?.wrapper} className={classNames?.wrapper}>
			<div css={style?.flag} className={classNames?.flag}>
				<p css={style?.number} className={classNames?.number}>
					{companyIndex}
				</p>
			</div>

			<BaseAwardText style={style?.text} icon={icon} classNames={classNames} />
		</div>
	);
};

export default BaseServiceFlag;

BaseServiceFlag.propTypes = {
	style: PropTypes.object,
	classNames: PropTypes.object,
	icon: PropTypes.element,
};
