import { Icon } from "../../../../components/BaseComponents/Icon";

export const ShareIcon = (props) => (
	<Icon fill="none" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M13 4h6a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V7.4l-5.3 5.3a1 1 0 1 1-1.4-1.42L16.58 6H13a1 1 0 1 1 0-2Zm4.3 11.3a1 1 0 0 1 1.7.7v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h2a1 1 0 0 1 0 2H6v11h11v-2a1 1 0 0 1 .3-.7Z"
			clipRule="evenodd"
		/>
	</Icon>
);
