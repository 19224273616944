export const constructOutboundUrl = (companyReviewPath, extraQueryParams) => {
	const tempOrigin = "http://local.applied-minds.com";
	const url = new URL(companyReviewPath, tempOrigin);
	const searchParams = new URLSearchParams(url.search);

	if (extraQueryParams) {
		Object.entries(extraQueryParams).forEach(([key, value]) => {
			searchParams.set(key, value);
		});
	}

	url.search = searchParams.toString();
	return url.toString().replace(tempOrigin, "");
};
