import { useContext } from "react";
import { twMerge } from "@/helpers/twMerge";
import { getIsTargetedPath } from "@/selectors";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { constructOutboundUrl } from "../../helpers/constructOutboundUrl";
import { useOutboundPaths } from "../../links/useOutboundPaths";
import { CompanyContext } from "../CompanyList/CompanyContext";
import OutboundLink from "../general/OutboundLink";
import BaseLinkWrapper from "./BaseLinkWrapper";

export const BaseReviewLinkWrapper = (props) => {
	const { children, extraQueryParams, className, css } = props;
	const isTargetedPath = useSelector(getIsTargetedPath);
	if (isTargetedPath) return <BaseLinkWrapper {...props} />;

	const classNameProp = twMerge(className);
	const { company } = useContext(CompanyContext);
	const { isCompanyReviewLinkable, companyReviewPath } = useOutboundPaths();
	if (!isCompanyReviewLinkable) return <BaseLinkWrapper {...props} />;

	const outboundUrl = constructOutboundUrl(companyReviewPath, extraQueryParams);

	return (
		<OutboundLink
			to={outboundUrl}
			company={company}
			className={classNameProp}
			css={css}
		>
			{children}
		</OutboundLink>
	);
};

export default BaseReviewLinkWrapper;

BaseReviewLinkWrapper.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	className: PropTypes.string,
	css: PropTypes.object,
	extraQueryParams: PropTypes.object,
};
