/* constants for verticals  */
export const TAX_RELIEF_DEFAULT_PATH = "tax-relief";
export const CREDIT_REPAIR_DEFAULT_PATH = "credit-repair";
export const CREDIT_MONITORING_DEFAULT_PATH = "credit-monitoring";
export const DEBT_RELIEF_DEFAULT_PATH = "debt-relief";
export const BUSINESS_LOANS_DEFAULT_PATH = "business-loans";
export const PERSONAL_LOANS_DEFAULT_PATH = "personal-loans";
export const STUDENT_LOANS_REFINANCE_DEFAULT_PATH = "student-loan-refinance";
export const CREDIT_BUILDER_LOANS_DEFAULT_PATH = "credit-builder-loans";
export const PAYDAY_LOANS_PATH = "payday-loans";
export const IDENTITY_THEFT_PROTECTION_PATH = "identity-theft-protection";
export const AUTO_REFINANCE_PATH = "auto-refinance";
export const MORTGAGE_REFINANCE_PATH = "mortgage-refinance";
export const BUSINESS_BANKING_PATH = "business-banking";
export const BILL_NEGOTIATION_PATH = "bill-negotiation";
export const SHARED_EQUITY_AGREEMENTS_PATH = "shared-equity-agreements";
export const ALTERNATIVE_INVESTMENTS_PATH = "alternative-investments";
export const TAX_PREPARATION_SERVICES_PATH = "tax-preparation-services";
export const SPEND_MANAGEMENT_PATH = "spend-management";
export const FINANCIAL_ADVISORS_PATH = "financial-advisors";

/* URL filter parameters */
export const BAD_CREDIT_URL_PARAMETER = "bad-credit";
export const FAST_LOANS_URL_PARAMETER = "fast-loans";
export const QUICK_LOANS_URL_PARAMETER = "quick-loans";
export const EMERGENCY_LOANS_URL_PARAMETER = "emergency-loans";
export const FAIR_CREDIT_URL_PARAMETER = "fair-credit";
export const GOOD_CREDIT_URL_PARAMETER = "good-credit";
export const EXCELLENT_CREDIT_URL_PARAMETER = "excellent-credit";
export const ONE_THOUSAND_URL_PARAMETER = "1000-loan";
export const FIVE_THOUSAND_URL_PARAMETER = "5000-loan";
export const TEN_THOUSAND_URL_PARAMETER = "10000-loan";
export const TWENTY_FIVE_THOUSAND_URL_PARAMETER = "25000-loan";
export const WORKING_CAPITAL_LOANS_URL_PARAMETER = "working-capital-loans";
export const LINE_OF_CREDIT_URL_PARAMETER = "line-of-credit";
export const INVOICE_FACTORING_URL_PARAMETER = "invoice-factoring";
export const TERM_LOAN_URL_PARAMETER = "term-loans";
export const SBA_LOAN_URL_PARAMETER = "sba-loans";
export const FUNDING_MARKETPLACE_URL_PARAMETER = "funding-marketplace";

/* Constants used for filtering UI*/
export const WORKING_CAPITAL_LOANS_FILTER = "Working Capital Loans";
export const LINE_OF_CREDIT_FILTER = "Line of Credit";
export const INVOICE_FACTORING_FILTER = "Invoice Factoring";
export const TERM_LOAN_FILTER = "Term Loan";
export const SBA_LOANS_FILTER = "SBA Loan";
export const FUNDING_MARKETPLACE_FILTER = "Funding Marketplace";
export const ONE_THOUSAND_FILTER = 1000;
export const FIVE_THOUSAND_FILTER = 5000;
export const TEN_THOUSAND_FILTER = 10000;
export const TWENTY_FIVE_THOUSAND_FILTER = 25000;
export const BAD_CREDIT_RATING = "Poor";
export const FAIR_CREDIT_RATING = "Fair";
export const GOOD_CREDIT_RATING = "Good";
export const EXCELLENT_CREDIT_RATING = "Excellent";

/* Also constants for filtering UI, specifically filtering keys*/
export const CREDIT_QUALITY_FILTERING_KEY = "creditQuality";
export const LOAN_AMOUNT_FILTERING_KEY = "loanAmount";
export const FUNDING_TYPE_FILTERING_KEY = "fundingType";
export const MONTHLY_REVENUE_FILTERING_KEY = "monthlyRevenue";

export const TCR_SITE_CONFIGURATION = {
	[PERSONAL_LOANS_DEFAULT_PATH]: {
		numberOfCompaniesToRender: {
			targeted: 6,
			nonTargeted: 6,
		},
		reviewWidgets: {
			widget: "trustpilot",
		},
		companyListHeaderParts: [
			"provider",
			"Est.APR",
			"Loan Term",
			"Max Loan",
			"Why We Recommend",
		],
		serviceRightColumns: [
			{
				fields: ["minimumApr", "maximumApr"],
				label: "APR Range",
				formatter: ([minimumApr, maximumApr]) =>
					`${minimumApr} - ${maximumApr}%`,
			},
			{
				fields: ["minimumLoanTerm", "maximumLoanTerm"],
				label: "Loan Term",
				formatter: ([minimumLoanTerm, maximumLoanTerm]) =>
					`${minimumLoanTerm} - ${maximumLoanTerm} mo`,
				defaultValue: "24 - 84 mo",
			},
			{
				fields: "maximumLoanAmount",
				label: "max loan",
				preFormatterConversions: {
					currencyConversions: {
						isConverted: true,
					},
				},
			},
		],
		isMobileRatingUsedForMobileTargetedPaths: true,
		filteringInformation: {
			mobileFilteringModal: {
				modalText: "How's your credit score?",
				buttons: [
					{
						label: BAD_CREDIT_RATING,
						value: BAD_CREDIT_RATING,
						filteringKey: CREDIT_QUALITY_FILTERING_KEY,
					},
					{
						label: FAIR_CREDIT_RATING,
						value: FAIR_CREDIT_RATING,
						filteringKey: CREDIT_QUALITY_FILTERING_KEY,
					},
					{
						label: GOOD_CREDIT_RATING,
						value: GOOD_CREDIT_RATING,
						filteringKey: CREDIT_QUALITY_FILTERING_KEY,
					},
					{
						label: EXCELLENT_CREDIT_RATING,
						value: EXCELLENT_CREDIT_RATING,
						filteringKey: CREDIT_QUALITY_FILTERING_KEY,
					},
				],
			},
		},
		isTargetedPathFilteringAllowed: true,
		isNonTargetedPathFilteringAllowed: true,
		isTargetedPathModalFilteringAllowed: true,
		isServiceRightReviewLinkRenderedWithDate: true,
		modalQueryParam: "fromLoanModal",
	},
	[CREDIT_REPAIR_DEFAULT_PATH]: {
		numberOfCompaniesToRender: {
			targeted: 6,
			nonTargeted: 6,
		},
		isServiceBlockLegacyStyled: true,
	},
	[DEBT_RELIEF_DEFAULT_PATH]: {
		isServiceBlockLegacyStyled: true,
		serviceBlockCallout: {
			field: "minimumDebtAmount",
			preFormatterConversions: {
				isConvertedToCurrency: true,
			},
			formatter: (postConversionValue) =>
				`Minimum Debt: ${postConversionValue}`,
		},
	},
	[CREDIT_MONITORING_DEFAULT_PATH]: {
		reviewWidgets: {
			widget: "trustpilot",
		},
		companyListHeaderParts: [
			"provider",
			"Starting price",
			"Reports Included",
			"Credit score update frequency",
			"Why We Recommend",
		],
		serviceRightColumns: [
			{
				fields: "startingMonthlyPricing",
				label: "Starting price",
				preFormatterConversions: {
					currencyConversions: {
						isConverted: true,
					},
				},
				formatter: (minimumMonthlyRevenue) => `${minimumMonthlyRevenue}/mo`,
			},
			{ fields: "creditScoresIncluded", label: "Reports Included" },
			{
				fields: "creditScoreUpdateFrequency",
				label: "Credit score update frequency",
			},
		],
	},
	[BUSINESS_LOANS_DEFAULT_PATH]: {
		numberOfCompaniesToRender: {
			nonTargeted: 7,
		},
		reviewWidgets: {
			widget: "webReview",
			webReviewDesign: "newNonSoftware",
		},
		companyListHeaderParts: [
			"Provider",
			"Min. Time In Business",
			"Min. Revenue",
			"BBB Rating",
			"Why we recommend",
		],
		serviceRightColumns: [
			{ fields: "minimumTimeInBusiness", label: "Min. Time in Business" },
			{
				fields: "minimumMonthlyRevenue",
				label: "Min. Revenue",
				preFormatterConversions: {
					currencyConversions: {
						isConverted: true,
						isAbbreviated: true,
					},
				},
				formatter: (minimumMonthlyRevenue) => `${minimumMonthlyRevenue}/mo`,
			},
			{ fields: "bbbRating", label: "BBB Rating" },
		],
		filteringInformation: {
			filteringSelects: [
				{
					title: "Funding Type",
					filteringKey: FUNDING_TYPE_FILTERING_KEY,
					options: [
						{
							value: WORKING_CAPITAL_LOANS_FILTER,
							label: WORKING_CAPITAL_LOANS_FILTER,
						},
						{ value: LINE_OF_CREDIT_FILTER, label: LINE_OF_CREDIT_FILTER },
						{
							value: INVOICE_FACTORING_FILTER,
							label: INVOICE_FACTORING_FILTER,
						},
						{ value: TERM_LOAN_FILTER, label: TERM_LOAN_FILTER },
						{ value: SBA_LOANS_FILTER, label: SBA_LOANS_FILTER },
						{
							value: FUNDING_MARKETPLACE_FILTER,
							label: FUNDING_MARKETPLACE_FILTER,
						},
					],
				},
				{
					title: "Monthly Revenue",
					filteringKey: MONTHLY_REVENUE_FILTERING_KEY,
					options: [
						/*
              Due to the logic in the filterByMonthlyRevenue filtering function,
              we always want the value property to be an integer corresponding to the highest value in the label
              i.e. a label such as '$8,000 - $12,000' should have a value of 12000
              'Greater than $12,000' has a value of 100000 as it's higher than the minimum revenue requirement for any lender
             */
						{ value: 8000, label: "$0 - $8,000" },
						{ value: 12000, label: "$8,000 - $12,000" },
						{ value: 100000, label: "Greater than $12,000" },
					],
				},
			],
			mobileFilteringButtonSelects: [
				{
					title: "Funding Type",
					filteringKey: FUNDING_TYPE_FILTERING_KEY,
					options: [
						{
							value: WORKING_CAPITAL_LOANS_FILTER,
							label: WORKING_CAPITAL_LOANS_FILTER,
						},
						{ value: LINE_OF_CREDIT_FILTER, label: LINE_OF_CREDIT_FILTER },
						{
							value: INVOICE_FACTORING_FILTER,
							label: INVOICE_FACTORING_FILTER,
						},
						{ value: TERM_LOAN_FILTER, label: TERM_LOAN_FILTER },
						{ value: SBA_LOANS_FILTER, label: SBA_LOANS_FILTER },
						{
							value: FUNDING_MARKETPLACE_FILTER,
							label: FUNDING_MARKETPLACE_FILTER,
						},
					],
				},
				{
					title: "Monthly Revenue",
					filteringKey: MONTHLY_REVENUE_FILTERING_KEY,
					options: [
						/*
              Due to the logic in the filterByMonthlyRevenue filtering function,
              we always want the value property to be an integer corresponding to the highest value in the label
              i.e. a label such as '$8,000 - $12,000' should have a value of 12000
              'Greater than $12,000' has a value of 100000 as it's higher than the minimum revenue requirement for any lender
             */
						{ value: 8000, label: "$0 - $8,000" },
						{ value: 12000, label: "$8,000 - $12,000" },
						{ value: 100000, label: "Greater than $12,000" },
					],
				},
			],
			placeholders: {
				[WORKING_CAPITAL_LOANS_URL_PARAMETER]: {
					[FUNDING_TYPE_FILTERING_KEY]: WORKING_CAPITAL_LOANS_FILTER,
				},
				[LINE_OF_CREDIT_URL_PARAMETER]: {
					[FUNDING_TYPE_FILTERING_KEY]: LINE_OF_CREDIT_FILTER,
				},
				[INVOICE_FACTORING_URL_PARAMETER]: {
					[FUNDING_TYPE_FILTERING_KEY]: INVOICE_FACTORING_FILTER,
				},
				[TERM_LOAN_URL_PARAMETER]: {
					[FUNDING_TYPE_FILTERING_KEY]: TERM_LOAN_FILTER,
				},
				[SBA_LOAN_URL_PARAMETER]: {
					[FUNDING_TYPE_FILTERING_KEY]: SBA_LOANS_FILTER,
				},
				[FUNDING_MARKETPLACE_URL_PARAMETER]: {
					[FUNDING_TYPE_FILTERING_KEY]: FUNDING_MARKETPLACE_FILTER,
				},
				DEFAULT: {
					[FUNDING_TYPE_FILTERING_KEY]: "All Types",
					[MONTHLY_REVENUE_FILTERING_KEY]: "Your Monthly Rev.",
				},
			},
		},
		isTargetedPathFilteringAllowed: true,
		isNonTargetedPathFilteringAllowed: true,
	},
	[STUDENT_LOANS_REFINANCE_DEFAULT_PATH]: {
		showFeaturedCompany: true,
		companyListHeaderParts: [
			"provider",
			"Minimum Credit Score",
			"Starting Fixed APR",
			"BBB Rating",
			"Why We Recommend",
		],
		isServiceRightReviewLinkRenderedWithDate: true,
		allowAwardTextOnAllCompanies: true,
		reviewWidgets: { widget: "trustpilot" },
		serviceRightColumns: [
			{ fields: "creditScoreRequired", label: "Min. Credit Score" },
			{
				fields: "startingFixedApr",
				label: "Starting Fixed APR",
				formatter: (startingFixedApr) => `${startingFixedApr}%`,
			},
			{ fields: "bbbRating", label: "BBB Rating" },
		],
	},
	[TAX_RELIEF_DEFAULT_PATH]: {
		numberOfCompaniesToRender: {
			targeted: 6,
			nonTargeted: 6,
		},
		serviceBlockCallout: {
			field: "minimumDebtAmount",
			preFormatterConversions: {
				isConvertedToCurrency: true,
			},
			formatter: (postConversionValue) =>
				`${postConversionValue}+ Tax Amount Required`,
		},
		isServiceBlockLegacyStyled: true,
	},
	[CREDIT_BUILDER_LOANS_DEFAULT_PATH]: {
		isServiceBlockLegacyStyled: true,
	},
	[PAYDAY_LOANS_PATH]: {
		companyListHeaderParts: [
			"provider",
			"BBB Rating",
			"Year Founded",
			"Why We Recommend",
		],
		serviceRightColumns: [
			{ fields: "bbbRating", label: "BBB Rating" },
			{ fields: "yearFounded", label: "Year Founded" },
		],
		isServiceRightReviewLinkRenderedWithDate: true,
	},
	[IDENTITY_THEFT_PROTECTION_PATH]: {
		reviewWidgets: {
			widget: "trustpilot",
		},
		companyListHeaderParts: [
			"provider",
			"Starting Pricing",
			"Identity Theft Insurance",
			"Year Founded",
			"Why We Recommend",
		],
		serviceRightColumns: [
			{
				fields: "startingMonthlyPricing",
				label: "Starting Pricing",
				preFormatterConversions: {
					currencyConversions: {
						isConverted: true,
					},
				},
				formatter: (startingMonthlyPricing) => `${startingMonthlyPricing}/mo`,
			},
			{
				fields: "identityTheftInsuranceAmount",
				label: "Identity Theft Insurance",
				formatter: (insuranceAmount) => `Up to ${insuranceAmount}*`,
			},
			{ fields: "yearFounded", label: "Year Founded" },
		],
	},
	[AUTO_REFINANCE_PATH]: {
		numberOfCompaniesToRender: {
			targeted: 6,
			nonTargeted: 6,
		},
		showFeaturedCompany: true,
		isServiceRightReviewLinkRenderedWithDate: true,
		companyListHeaderParts: [
			"provider",
			"Starting APR",
			"Customers Served",
			"Why We Recommend",
		],
		serviceRightColumns: [
			{
				fields: "startingApr",
				label: "Starting APR",
				formatter: (startingApr) => `${startingApr}%`,
			},
			{
				fields: "numberOfCustomersServed",
				label: "Customers Served",
				preFormatterConversions: { numbers: { addCommaSeparator: true } },
			},
		],
		isTargetedPathFilteringAllowed: true,
		isNonTargetedPathFilteringAllowed: true,
		reviewWidgets: {
			widget: "trustpilot",
		},
		filteringInformation: {
			filteringSelects: [
				{
					title: "Credit Score",
					filteringKey: CREDIT_QUALITY_FILTERING_KEY,
					options: [
						{
							value: BAD_CREDIT_RATING,
							label: `${BAD_CREDIT_RATING} (350 - 629)`,
						},
						{
							value: FAIR_CREDIT_RATING,
							label: `${FAIR_CREDIT_RATING} (630 - 689)`,
						},
						{
							value: GOOD_CREDIT_RATING,
							label: `${GOOD_CREDIT_RATING} (690 - 719)`,
						},
						{
							value: EXCELLENT_CREDIT_RATING,
							label: `${EXCELLENT_CREDIT_RATING} (720 - 850)`,
						},
					],
				},
			],
			placeholders: {
				DEFAULT: {
					[CREDIT_QUALITY_FILTERING_KEY]: `${GOOD_CREDIT_RATING} (690 - 719)`,
				},
			},
			mobileFilteringButtonSelects: [
				{
					title: "Credit Score",
					filteringKey: CREDIT_QUALITY_FILTERING_KEY,
					options: [
						{
							value: BAD_CREDIT_RATING,
							label: `${BAD_CREDIT_RATING} (350 - 629)`,
						},
						{
							value: FAIR_CREDIT_RATING,
							label: `${FAIR_CREDIT_RATING} (630 - 689)`,
						},
						{
							value: GOOD_CREDIT_RATING,
							label: `${GOOD_CREDIT_RATING} (690 - 719)`,
						},
						{
							value: EXCELLENT_CREDIT_RATING,
							label: `${EXCELLENT_CREDIT_RATING} (720 - 850)`,
						},
					],
				},
			],
			mobileFilteringModal: {
				modalText: "How's your credit score?",
				buttons: [
					{
						label: BAD_CREDIT_RATING,
						value: BAD_CREDIT_RATING,
						filteringKey: CREDIT_QUALITY_FILTERING_KEY,
					},
					{
						label: FAIR_CREDIT_RATING,
						value: FAIR_CREDIT_RATING,
						filteringKey: CREDIT_QUALITY_FILTERING_KEY,
					},
					{
						label: GOOD_CREDIT_RATING,
						value: GOOD_CREDIT_RATING,
						filteringKey: CREDIT_QUALITY_FILTERING_KEY,
					},
					{
						label: EXCELLENT_CREDIT_RATING,
						value: EXCELLENT_CREDIT_RATING,
						filteringKey: CREDIT_QUALITY_FILTERING_KEY,
					},
				],
			},
		},
	},
	[MORTGAGE_REFINANCE_PATH]: {
		reviewWidgets: {
			widget: "trustpilot",
		},
		companyListHeaderParts: [
			"provider",
			"Minimum credit score",
			"Alternative credit considered?",
			"National or Regional?",
			"Why We Recommend",
		],
		serviceRightColumns: [
			{ fields: "minimumCreditScore", label: "Minimum credit score" },
			{
				fields: "alternativeCreditConsideration",
				label: "Alternative credit considered?",
			},
			{ fields: "nationalOrRegional", label: "National / Regional" },
		],
	},
	[BUSINESS_BANKING_PATH]: {
		numberOfCompaniesToRender: {
			targeted: 7,
			nonTargeted: 7,
		},
		showFeaturedCompany: true,
		allowAwardTextOnAllCompanies: true,
		companyListHeaderParts: [
			"provider",
			"Cashback Rewards",
			"Monthly service fees",
			"Interest Rate",
			"Why We Recommend",
		],
		serviceRightColumns: [
			{ fields: "cashbackPercentage", label: "Cashback Rewards" },
			{ fields: "monthlyServiceFees", label: "Monthly service fees" },
			{
				fields: "interestRate",
				label: "Interest Rate",
				formatter: (interestRate) => {
					const numValue = Number(interestRate);
					return isNaN(numValue) ? "N/A" : `${numValue.toFixed(2)}%`;
				},
			},
		],
		reviewWidgets: {
			widget: "webReview",
			webReviewDesign: "newNonSoftware",
		},
	},
	[BILL_NEGOTIATION_PATH]: {
		reviewWidgets: {
			widget: "trustpilot",
		},
		companyListHeaderParts: [
			"provider",
			"Year Founded",
			"Pricing",
			"BBB Rating",
			"Why We Recommend",
		],
		serviceRightColumns: [
			{ fields: "yearFounded", label: "Year Founded" },
			{ fields: "pricing", label: "Pricing" },
			{ fields: "bbbRating", label: "BBB Rating" },
		],
	},
	[SHARED_EQUITY_AGREEMENTS_PATH]: {
		reviewWidgets: {
			widget: "trustpilot",
		},
		companyListHeaderParts: [
			"provider",
			"Maximum Investment",
			"Term Length",
			"Service Fee",
			"Why We Recommend",
		],
		serviceRightColumns: [
			{
				fields: "maximumInvestment",
				label: "Maximum Investment",
				formatter: (maximumInvestment) =>
					`$${maximumInvestment.toLocaleString("en-US")}`,
			},
			{ fields: "termLength", label: "Term Length" },
			{ fields: "serviceFee", label: "Service Fee" },
		],
	},
	[ALTERNATIVE_INVESTMENTS_PATH]: {
		reviewWidgets: {
			widget: "webReview",
		},
		companyListHeaderParts: [
			"Platform",
			"Year Founded",
			"Investment Type",
			"Minimum Investment",
			"Why We Recommend",
		],
		serviceRightColumns: [
			{ fields: "yearFounded", label: "Year Founded" },
			{ fields: "investmentType", label: "Investment Type" },
			{ fields: "minimumInvestment", label: "Minimum Investment" },
		],
	},
	[TAX_PREPARATION_SERVICES_PATH]: {
		reviewWidgets: {
			widget: "webReview",
		},
		isOnlyAllowedToRenderVisitLinks: true,
		usesRevampedPhoneLink: true,
		companyListHeaderParts: [
			"Tax Preparation Service",
			"Starting Pricing",
			"In-person Option?",
			"Year Founded",
			"Why We Recommend",
		],
		serviceRightColumns: [
			{ fields: "pricing", label: "Pricing" },
			{ fields: "inPersonOption", label: "In-person Option?" },
			{ fields: "yearFounded", label: "Year Founded" },
		],
	},
	[SPEND_MANAGEMENT_PATH]: {
		showFeaturedCompany: true,
		companyListHeaderParts: [
			"provider",
			"Rewards",
			"Support",
			"Why We Recommend",
		],
		serviceRightColumns: [
			{ fields: "rewards", label: "Rewards" },
			{ fields: "support", label: "Support" },
		],
		reviewWidgets: {
			widget: "webReview",
			webReviewDesign: "newNonSoftware",
		},
	},
	[FINANCIAL_ADVISORS_PATH]: {
		preventRenderingColumns: true,
		preventRenderingReviewLink: true,
		preventRenderingCompanyListHeader: true,
		allowAwardTextOnAllCompanies: true,
		reviewWidgets: {
			widget: "webReview",
			webReviewDesign: "newNonSoftware",
		},
	},
};
