import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { RC_DOMAIN, SITE } from "../../../config/constants";
import { ExpandMobileFiltersIcon } from "../../client/assets/icons/thecreditreview/ExpandMobileFiltersIcon";
import {
	getFilter,
	getFilteringParametersCount,
	getIsNotModalFiltered,
	getIsNotUserFiltered,
	getModalSelection,
} from "../../selectors";
import {
	resetFilteringState,
	setFilteringState,
} from "../../store/filtering/actions";

const FilterContext = createContext();

export const FilterProvider = ({ children, placeholders }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [filteringParameters, setFilteringParameters] = useState({
		isNotUserFiltered: true,
	});

	const dispatch = useDispatch();
	const filterCount = useSelector(getFilteringParametersCount);

	const toggle = useCallback(() => {
		setIsOpen((prev) => !prev);
	}, []);

	const applyFilters = useCallback(() => {
		toggle();
		dispatch(setFilteringState(filteringParameters));
	}, [filteringParameters, dispatch, toggle]);

	const clearFilters = useCallback(() => {
		setFilteringParameters({ isNotUserFiltered: true });
		toggle();
		dispatch(resetFilteringState());
	}, [dispatch, toggle]);

	const value = useMemo(
		() => ({
			isOpen,
			toggle,
			filterCount,
			filteringParameters,
			setFilteringParameters,
			applyFilters,
			clearFilters,
			placeholders,
		}),
		[
			isOpen,
			toggle,
			filterCount,
			filteringParameters,
			setFilteringParameters,
			applyFilters,
			clearFilters,
			placeholders,
		],
	);

	return (
		<FilterContext.Provider value={value}>{children}</FilterContext.Provider>
	);
};

FilterProvider.propTypes = {
	children: PropTypes.node.isRequired,
	placeholders: PropTypes.object.isRequired,
};

export const useFilter = () => {
	const context = useContext(FilterContext);
	if (!context) {
		throw new Error("useFilter must be used within FilterProvider");
	}

	return context;
};

export const useFilterSelect = (filteringKey, placeholders) => {
	const dispatch = useDispatch();
	const parentFilteringSelection = useSelector((state) =>
		getModalSelection(state, { filteringKey }),
	);
	const parentFilteringValue = useMemo(
		() =>
			typeof parentFilteringSelection === "object"
				? parentFilteringSelection.value
				: parentFilteringSelection,
		[parentFilteringSelection],
	);
	const parentIsNotUserFiltered = useSelector(getIsNotUserFiltered);
	const filter = useSelector(getFilter);

	const isRouteFiltered = Object.keys(placeholders)
		.filter((key) => key !== "DEFAULT")
		.includes(filter);
	const routeFilteredOption = placeholders[filter]?.[filteringKey]?.value;

	const [selectedOption, setSelectedOption] = useState(() => {
		if (parentIsNotUserFiltered && isRouteFiltered) {
			return routeFilteredOption;
		}

		return parentFilteringValue ?? "default";
	});

	const isNotModalFiltered = useSelector(getIsNotModalFiltered);

	useEffect(() => {
		if (SITE === RC_DOMAIN && routeFilteredOption === "default") return;

		if (parentIsNotUserFiltered && isRouteFiltered) {
			dispatch(
				setFilteringState({
					[filteringKey]: routeFilteredOption,
					isNotUserFiltered: true,
				}),
			);
		}
	}, [
		parentIsNotUserFiltered,
		isRouteFiltered,
		filteringKey,
		routeFilteredOption,
		dispatch,
	]);

	useEffect(() => {
		if (
			parentIsNotUserFiltered &&
			!isNotModalFiltered &&
			parentFilteringValue
		) {
			setSelectedOption(parentFilteringValue);
		}
	}, [parentIsNotUserFiltered, isNotModalFiltered, parentFilteringValue]);

	return {
		selectedOption,
		setSelectedOption,
	};
};

export const useMobileFilterSelect = (filteringSelect) => {
	const { filteringKey } = filteringSelect;
	const { setFilteringParameters, placeholders } = useFilter();
	const { selectedOption, setSelectedOption } = useFilterSelect(
		filteringKey,
		placeholders,
	);

	const [localSelectedOption, setLocalSelectedOption] = useState(null);
	const [isOpen, setIsOpen] = useState(false);

	const toggle = useCallback(() => {
		setIsOpen((prev) => !prev);
	}, []);

	const handleChange = useCallback(
		(event) => {
			const { value } = event.target;
			if (!value) return;

			setSelectedOption(value);
			setLocalSelectedOption(value);
			setFilteringParameters((prev) => ({
				...prev,
				[filteringKey]: value,
				isNotUserFiltered: false,
			}));
			toggle();
		},
		[
			filteringKey,
			setFilteringParameters,
			setSelectedOption,
			setLocalSelectedOption,
			toggle,
		],
	);

	return {
		isOpen,
		toggle,
		selectedOption,
		localSelectedOption,
		handleChange,
	};
};

export const useDesktopFilterSelect = (filteringSelect, placeholders) => {
	const { filteringKey } = filteringSelect;
	const { setFilteringParameters } = useFilter();
	const { selectedOption, setSelectedOption } = useFilterSelect(
		filteringKey,
		placeholders,
	);

	const dispatch = useDispatch();
	const handleChange = useCallback(
		(event) => {
			const { value } = event.target;
			if (!value) return;

			setSelectedOption(value);

			const newFilteringParameters = {
				[filteringKey]: value,
				isNotUserFiltered: false,
			};
			setFilteringParameters((prev) => ({
				...prev,
				...newFilteringParameters,
			}));
			dispatch(setFilteringState(newFilteringParameters));
		},
		[filteringKey, dispatch, setSelectedOption, setFilteringParameters],
	);

	return {
		selectedOption,
		handleChange,
	};
};

export const ExpandMobileFilters = () => {
	const { isOpen, toggle, filterCount } = useFilter();
	if (isOpen) return null;

	const filterLabel = filterCount > 1 ? "Filters" : "Filter";

	return (
		<div
			role="button"
			aria-haspopup="true"
			aria-label="Filter Results"
			onClick={toggle}
			className="tw-flex tw-h-[36px] tw-cursor-pointer tw-items-center tw-justify-center"
		>
			<ExpandMobileFiltersIcon className="tw-h-[15px] tw-w-[16px] tw-text-white" />
			<p className="tw-m-0 tw-flex tw-text-[15px] tw-font-semibold tw-leading-[15px] tw-text-white">
				{filterLabel}
				{filterCount > 0 && (
					<span className="tw-ml-[6px] tw-mt-[-4px] tw-rounded-full tw-bg-white tw-px-[7px] tw-pt-[3px] tw-text-[12px] tw-text-[#ff5161]">
						{filterCount}
					</span>
				)}
			</p>
		</div>
	);
};

export const MobileFilters = ({ children }) => {
	const { isOpen, toggle, filterCount, applyFilters, clearFilters } =
		useFilter();
	if (!isOpen) return null;

	return (
		<div className="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-isolate tw-flex tw-transition-all">
			{/* Gray overlay */}
			<div
				className="tw-fixed tw-left-0 tw-top-0 tw-z-1 tw-h-[calc(100vh-50px)] tw-w-full tw-bg-[gray]/30 tw-transition-all"
				onClick={toggle}
			/>

			{/* Filter container */}
			<div className="tw-z-2 tw-flex tw-w-full tw-flex-col tw-rounded-t-[10px] tw-bg-white tw-p-[10px]">
				<p className="tw-my-[10px] tw-inline-flex tw-items-center tw-justify-center tw-text-center tw-text-[16px] tw-font-semibold tw-text-[#111]">
					Filter Results
					{filterCount > 0 && (
						<span className="tw-ml-[6px] tw-mt-[-4px] tw-rounded-full tw-bg-[#ff5161] tw-px-[7px] tw-pt-[3px] tw-text-[12px] tw-text-white">
							{filterCount}
						</span>
					)}
				</p>
				{children}
				<div className="tw-flex tw-justify-end">
					<button
						type="button"
						onClick={clearFilters}
						className="tw-mt-[15px] tw-inline-block tw-h-[40px] tw-w-[30%] tw-cursor-pointer tw-touch-manipulation tw-select-none tw-whitespace-nowrap tw-rounded-[3px] tw-border-0 tw-bg-white tw-p-[7px] tw-text-center tw-align-middle tw-font-[AvenirNextLTPro-Demi] tw-text-[13px] tw-leading-[1.4375em] tw-text-[#111] hover:tw-text-[#1d8ce0] focus:tw-outline-none"
					>
						Clear All
					</button>
					<button
						type="button"
						onClick={applyFilters}
						className="tw-mt-[15px] tw-inline-block tw-h-[40px] tw-w-[30%] tw-cursor-pointer tw-touch-manipulation tw-select-none tw-self-end tw-whitespace-nowrap tw-rounded-[3px] tw-border-0 tw-bg-[#1774ff] tw-p-[7px] tw-text-center tw-align-middle tw-font-[AvenirNextLTPro-Demi] tw-text-[13px] tw-leading-[1.4375em] tw-text-white focus:tw-outline-none"
					>
						Apply Filters
					</button>
				</div>
			</div>
		</div>
	);
};

MobileFilters.propTypes = {
	children: PropTypes.node.isRequired,
};

// Rate Compare styled components
export const ExpandMobileFiltersRC = () => {
	const { isOpen, toggle } = useFilter();
	if (isOpen) return null;

	const filterLabel = "Filters";

	return (
		<div
			role="button"
			aria-haspopup="true"
			aria-label="Filter Results"
			onClick={toggle}
			className="tw-flex tw-h-11 tw-cursor-pointer tw-flex-row tw-items-center"
		>
			<p className="tw-m-0 tw-flex tw-text-base tw-font-semibold tw-leading-4 tw-text-white">
				{filterLabel}
			</p>
		</div>
	);
};

export const MobileFiltersRC = ({ children }) => {
	const { isOpen, toggle, applyFilters, clearFilters } = useFilter();
	if (!isOpen) return null;

	return (
		<div className="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-isolate tw-flex tw-transition-all">
			{/* Gray overlay */}
			<div
				className="tw-fixed tw-left-0 tw-top-0 tw-z-1 tw-h-[calc(100vh-50px)] tw-w-full tw-bg-[gray]/30 tw-transition-all"
				onClick={toggle}
			/>

			{/* Filter container */}
			<div className="tw-z-2 tw-flex tw-w-full tw-flex-col tw-rounded-t-[10px] tw-bg-white tw-p-[10px]">
				<p className="tw-my-[10px] tw-inline-flex tw-items-center tw-justify-center tw-text-center tw-text-[16px] tw-font-semibold tw-text-[#111]">
					Filter Results
				</p>
				{children}
				<div className="tw-flex tw-justify-around tw-py-4">
					<button
						type="button"
						onClick={clearFilters}
						className="tw-rounded-lg tw-bg-white tw-px-8 tw-py-5 tw-text-base tw-font-semibold tw-leading-6 tw-text-[#0756C0]"
					>
						Clear All
					</button>
					<button
						type="button"
						onClick={applyFilters}
						className="tw-rounded-lg tw-bg-[#0756C0] tw-px-8 tw-py-5 tw-text-base tw-font-semibold tw-leading-6 tw-text-white"
					>
						Apply Filters
					</button>
				</div>
			</div>
		</div>
	);
};

MobileFiltersRC.propTypes = {
	children: PropTypes.node.isRequired,
};
