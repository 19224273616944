import { Icon } from "../../../../components/BaseComponents/Icon";

export const RatingIcon = (props) => (
	<Icon fill="none" viewBox="0 0 14 13" {...props}>
		<path
			fill="currentColor"
			d="M6.55.98a.5.5 0 0 1 .9 0l1.4 3.05a.5.5 0 0 0 .4.28l3.33.4c.42.05.6.57.28.86L10.4 7.85a.5.5 0 0 0-.15.46l.65 3.29a.5.5 0 0 1-.73.53L7.24 10.5a.5.5 0 0 0-.48 0l-2.93 1.63a.5.5 0 0 1-.73-.53l.65-3.29a.5.5 0 0 0-.15-.46L1.14 5.57a.5.5 0 0 1 .28-.86l3.33-.4a.5.5 0 0 0 .4-.28L6.54.98Z"
		/>
	</Icon>
);
