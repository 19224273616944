import { useContext } from "react";
import { useSelector } from "react-redux";

import { RatingIcon } from "../../../client/assets/icons/insuranceranked/RatingIcon";
import { getAmBestRating } from "../../../selectors";
import { CompanyContext } from "../../CompanyList/CompanyContext";

const amBestRatingColorMap = {
	"A++": "tw-bg-[#00B679]",
	"A+": "tw-bg-[#00B679]",
	"A-": "tw-bg-[#00B679]",
	A: "tw-bg-[#00B679]",
	"B+": "tw-bg-[#16c661]",
	B: "tw-bg-[#16c661]",
	"B-": "tw-bg-[#16c661]",
	"C+": "tw-bg-[#ecbb15]",
	C: "tw-bg-[#ecbb15]",
	"C-": "tw-bg-[#ecbb15]",
	"D+": "tw-bg-[#f16338]",
	D: "tw-bg-[#f16338]",
	"D-": "tw-bg-[#f16338]",
	F: "tw-bg-[#c70000]",
};

export const AmBestRating = () => {
	const { company } = useContext(CompanyContext);
	const rating = useSelector((state) => getAmBestRating(state, { company }));
	if (!rating) return null;

	return (
		<div
			className={`tw-inline-flex tw-shrink-0 tw-items-center tw-gap-1 tw-rounded-[3px] tw-px-1.5 tw-py-1 ${
				amBestRatingColorMap[rating] || "tw-bg-[#00B679]"
			}`}
		>
			<RatingIcon className="tw-h-3.5 tw-w-3.5 tw-text-white" />
			<span className="tw-text-xs tw-font-semibold tw-leading-4 tw-text-white">
				Rating: {rating}
			</span>
		</div>
	);
};
