export const getStyles = ({ isFeaturedCompany }) => ({
	featuredCompanyHeader: {
		wrapper: "tw-p-2 tw-bg-[#EBF4FF] tw-flex tw-justify-center tw-items-center",
		contentWrapper:
			"tw-text-center tw-font-semibold tw-text-[#2399DC] tw-text-base tw-leading-6 tw-text-balance",
	},
	serviceFlag: {
		wrapper: "tw-pointer-events-none",
		flag: `tw-absolute tw-left-0 tw-top-0 tw-size-6 tw-flex tw-items-center tw-justify-center tw-rounded-[3px] tw-text-white tw-bg-[#2299DC] md:tw-size-7`,
		number:
			"tw-m-0 tw-text-current tw-text-xs tw-font-medium tw-leading-4 md:tw-text-sm md:tw-leading-6",
		text: "tw-absolute tw-rounded-l-[3px] tw-top-0 tw-left-8 tw-text-white tw-py-1 tw-bg-[#2299DC] tw-text-xs tw-font-semibold tw-leading-4 tw-pl-2 tw-pr-[16px] md:tw-py-0.5 md:tw-text-sm md:tw-font-medium md:tw-leading-6 [clip-path:polygon(100%_0,calc(100%_-_10px)_50%,100%_100%,0_100%,0_0)]",
	},
	companyLogo: {
		picture: "tw-min-w-[160px]",
		image: "tw-h-auto tw-max-w-full",
	},
	trustpilotReview: {
		container: "tw-flex tw-flex-col tw-w-fit tw-text-center tw-min-w-[160px]",
		reviewCount:
			"tw-text-[#232227] tw-font-medium tw-text-xs tw-leading-3 md:tw-text-sm md:tw-leading-4",
		ratingContainer: "tw-mt-2 tw-flex tw-gap-0.5 tw-justify-center",
		starBase:
			"tw-relative tw-rounded tw-px-1 tw-pt-[3px] tw-pb-[5px] tw-flex tw-items-center tw-justify-center tw-text-white tw-text-xs md:tw-text-sm",
		ratingCountWrapper:
			"tw-mt-1.5 tw-flex tw-items-center tw-justify-center tw-gap-1 tw-text-[#232227] tw-h-3.5 tw-text-xs tw-leading-3 md:tw-mt-2 md:tw-text-sm md:tw-leading-4",
		ratingCount: "tw-font-semibold tw-mt-0.5",
		ratingCountText: "tw-font-normal",
		trustPilotLogo: "tw-h-3.5 tw-w-auto md:tw-h-4",
	},
	benefitsList: {
		wrapper: "tw-mt-4",
		list: "tw-flex tw-flex-col tw-gap-2 tw-justify-center tw-p-0 tw-m-0",
		listItem: "tw-flex tw-text-[#333] tw-text-sm tw-gap-2 tw-leading-6",
	},
	socialProof: {
		wrapper:
			"tw-relative tw-flex tw-mb-4 tw-rounded-lg tw-max-w-fit tw-bg-white [filter:drop-shadow(0px_0px_1px_rgba(214,228,247,1))_drop-shadow(0px_0px_2px_rgba(214,228,247,1))] tw-p-2 after:tw-absolute after:tw-right-[calc(50%-6px)] after:tw-size-2.5 after:tw-translate-x-0 after:tw--translate-y-2/4 after:tw-rotate-45 after:tw-bg-white after:tw-overflow-hidden after:tw-pointer-events-none after:tw-top-full",
		contentWrapper:
			"tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-2",
		contentIcon: "tw-text-[#00B679] tw-text-[32px]",
		content:
			"tw-min-w-[102px] tw-max-w-[106px] tw-leading-4 tw-font-medium tw-text-[11px] tw-text-left tw-text-[#333B51] tw-mb-0",
	},
	visitLink: {
		wrapper:
			"tw-inline-flex tw-w-full tw-justify-center tw-p-2 tw-bg-[#00B679] tw-rounded-[3px] tw-transition tw-duration-300 tw-gap-2 hover:tw-bg-[#00674a] tw-cursor-pointer lg:tw-py-3",
		contentWrapper:
			"tw-text-white tw-font-semibold tw-tracking-[-0.16px] tw-leading-6 tw-text-base",
		iconClass: "tw-text-2xl tw-text-white tw-inline-flex",
	},
	reviewLink: {
		wrapper: "tw-mt-2",
		text: "tw-text-[#2299DC] tw-text-center tw-font-medium tw-leading-6 tw-text-sm tw-block",
	},
});
