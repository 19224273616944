import { useMemo } from "react";
import PropTypes from "prop-types";

import {
	BAD_CREDIT_RATING,
	BAD_CREDIT_URL_PARAMETER,
	CREDIT_QUALITY_FILTERING_KEY,
	EMERGENCY_LOANS_URL_PARAMETER,
	EXCELLENT_CREDIT_RATING,
	EXCELLENT_CREDIT_URL_PARAMETER,
	FAIR_CREDIT_RATING,
	FAIR_CREDIT_URL_PARAMETER,
	FAST_LOANS_URL_PARAMETER,
	FIVE_THOUSAND_URL_PARAMETER,
	GOOD_CREDIT_RATING,
	GOOD_CREDIT_URL_PARAMETER,
	LOAN_AMOUNT_FILTERING_KEY,
	ONE_THOUSAND_URL_PARAMETER,
	QUICK_LOANS_URL_PARAMETER,
	TEN_THOUSAND_URL_PARAMETER,
	TWENTY_FIVE_THOUSAND_URL_PARAMETER,
} from "../../../config/site/thecreditreview";
import { NativeSelectDropdownIcon } from "../../client/assets/icons/insuranceranked/NativeSelectDropdownIcon";
import { MobileFilterSelectIcon } from "../../client/assets/icons/thecreditreview/MobileFilterSelectIcon";
import {
	ExpandMobileFilters,
	FilterProvider,
	MobileFilters,
	useDesktopFilterSelect,
	useMobileFilterSelect,
} from "./FilteringProvider";

export const FILTER_CONFIG = {
	filteringSelects: [
		{
			title: "Credit Score",
			filteringKey: CREDIT_QUALITY_FILTERING_KEY,
			options: [
				{ value: BAD_CREDIT_RATING, label: `${BAD_CREDIT_RATING} (350 - 629)` },
				{
					value: FAIR_CREDIT_RATING,
					label: `${FAIR_CREDIT_RATING} (630 - 689)`,
				},
				{
					value: GOOD_CREDIT_RATING,
					label: `${GOOD_CREDIT_RATING} (690 - 719)`,
				},
				{
					value: EXCELLENT_CREDIT_RATING,
					label: `${EXCELLENT_CREDIT_RATING} (720 - 850)`,
				},
			],
		},
	],
	placeholders: {
		[BAD_CREDIT_URL_PARAMETER]: {
			[LOAN_AMOUNT_FILTERING_KEY]: "$1,000",
			[CREDIT_QUALITY_FILTERING_KEY]: {
				value: BAD_CREDIT_RATING,
				label: `${BAD_CREDIT_RATING} (350 - 629)`,
			},
		},
		[FAST_LOANS_URL_PARAMETER]: {
			[LOAN_AMOUNT_FILTERING_KEY]: "$1,000",
			[CREDIT_QUALITY_FILTERING_KEY]: {
				value: BAD_CREDIT_RATING,
				label: `${BAD_CREDIT_RATING} (350 - 629)`,
			},
		},
		[QUICK_LOANS_URL_PARAMETER]: {
			[LOAN_AMOUNT_FILTERING_KEY]: "$1,000",
			[CREDIT_QUALITY_FILTERING_KEY]: {
				value: BAD_CREDIT_RATING,
				label: `${BAD_CREDIT_RATING} (350 - 629)`,
			},
		},
		[EMERGENCY_LOANS_URL_PARAMETER]: {
			[LOAN_AMOUNT_FILTERING_KEY]: "$1,000",
			[CREDIT_QUALITY_FILTERING_KEY]: {
				value: BAD_CREDIT_RATING,
				label: `${BAD_CREDIT_RATING} (350 - 629)`,
			},
		},
		[FAIR_CREDIT_URL_PARAMETER]: {
			[LOAN_AMOUNT_FILTERING_KEY]: "$2,500",
			[CREDIT_QUALITY_FILTERING_KEY]: {
				value: FAIR_CREDIT_RATING,
				label: `${FAIR_CREDIT_RATING} (630 - 689)`,
			},
		},
		[GOOD_CREDIT_URL_PARAMETER]: {
			[LOAN_AMOUNT_FILTERING_KEY]: "$5,000",
			[CREDIT_QUALITY_FILTERING_KEY]: {
				value: GOOD_CREDIT_RATING,
				label: `${GOOD_CREDIT_RATING} (690 - 719)`,
			},
		},
		[EXCELLENT_CREDIT_URL_PARAMETER]: {
			[LOAN_AMOUNT_FILTERING_KEY]: "$10,000",
			[CREDIT_QUALITY_FILTERING_KEY]: {
				value: EXCELLENT_CREDIT_RATING,
				label: `${EXCELLENT_CREDIT_RATING} (720 - 850)`,
			},
		},
		[ONE_THOUSAND_URL_PARAMETER]: {
			[LOAN_AMOUNT_FILTERING_KEY]: "$1,000",
			[CREDIT_QUALITY_FILTERING_KEY]: {
				value: BAD_CREDIT_RATING,
				label: `${BAD_CREDIT_RATING} (350 - 629)`,
			},
		},
		[FIVE_THOUSAND_URL_PARAMETER]: {
			[LOAN_AMOUNT_FILTERING_KEY]: "$5,000",
			[CREDIT_QUALITY_FILTERING_KEY]: {
				value: GOOD_CREDIT_RATING,
				label: `${GOOD_CREDIT_RATING} (690 - 719)`,
			},
		},
		[TEN_THOUSAND_URL_PARAMETER]: {
			[LOAN_AMOUNT_FILTERING_KEY]: "$10,000",
			[CREDIT_QUALITY_FILTERING_KEY]: {
				value: GOOD_CREDIT_RATING,
				label: `${GOOD_CREDIT_RATING} (690 - 719)`,
			},
		},
		[TWENTY_FIVE_THOUSAND_URL_PARAMETER]: {
			[LOAN_AMOUNT_FILTERING_KEY]: "$25,000",
			[CREDIT_QUALITY_FILTERING_KEY]: {
				value: GOOD_CREDIT_RATING,
				label: `${GOOD_CREDIT_RATING} (690 - 719)`,
			},
		},
		DEFAULT: {
			[LOAN_AMOUNT_FILTERING_KEY]: "$1,500",
			[CREDIT_QUALITY_FILTERING_KEY]: {
				value: GOOD_CREDIT_RATING,
				label: `${GOOD_CREDIT_RATING} (690 - 719)`,
			},
		},
	},
};

export const PersonalLoansFilter = () => (
	// Mobile View
	<FilterProvider placeholders={FILTER_CONFIG.placeholders}>
		<div className="tw-fixed tw-bottom-[15px] tw-left-0 tw-right-0 tw-z-[1] tw-flex tw-justify-center sm:tw-hidden">
			<div className="tw-rounded-[28px] tw-bg-[#ff5161] tw-px-[15px]">
				<ExpandMobileFilters />
				<MobileFilters>
					{FILTER_CONFIG.filteringSelects.map((select, idx) => (
						<MobileFilterSelect
							key={`${select.filteringKey}-${idx}`}
							filteringSelect={select}
						/>
					))}
				</MobileFilters>
			</div>
		</div>

		{/* Desktop View */}
		<div className="tw-hidden sm:tw-block">
			<div className="tw-relative tw-mt-[-22px] tw-w-full tw-bg-white tw-text-[100%] tw-shadow-[0_4px_4px_0_rgba(0,0,0,0.07)]">
				<div className="tw-relative tw-top-[24px] tw-mx-auto tw-mb-[40px] tw-flex tw-w-full tw-max-w-[500px] tw-items-center tw-rounded-[5px] tw-bg-white tw-p-[15px] tw-shadow-[2px_1px_20px_#d3d3d3] md:tw-top-[30px] md:tw-mb-0 md:tw-max-w-[690px] desktop:tw-max-w-[910px] lg2:tw-max-w-[1110px]">
					<div className="tw-flex tw-h-[40px] desktop:tw-w-[325px] lg2:tw-w-[350px]">
						{FILTER_CONFIG.filteringSelects.map((select, idx) => (
							<DesktopFilterSelect
								key={`${select.filteringKey}-${idx}`}
								filteringSelect={select}
								placeholders={FILTER_CONFIG.placeholders}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	</FilterProvider>
);

export const MobileFilterSelect = ({ filteringSelect }) => {
	const { title, options } = filteringSelect;
	const { isOpen, toggle, selectedOption, localSelectedOption, handleChange } =
		useMobileFilterSelect(filteringSelect);

	const displayValue = useMemo(
		() => options.find((option) => option.value === selectedOption)?.label,
		[options, selectedOption],
	);

	return (
		<>
			<div
				className="tw-flex tw-h-[40px] tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-rounded-[2px] tw-border tw-border-solid tw-border-[rgb(214,219,222)] tw-bg-white tw-px-[15px] tw-py-[13px] tw-text-[#333]"
				onClick={toggle}
				data-testid="select-container"
			>
				<div className="tw-relative tw-inline-flex tw-items-center">
					<p className="tw-my-[4px] tw-block tw-text-left tw-text-[14px] tw-font-semibold">
						{title}
					</p>
					{displayValue && !localSelectedOption && (
						<span className="tw-ml-[15px] tw-inline-block tw-h-[7px] tw-w-[7px] tw-rounded-full tw-bg-[#1d8ce0]" />
					)}
				</div>
				<div className="tw-inline-flex tw-items-center">
					{displayValue && (
						<p
							role="button"
							aria-haspopup="true"
							className="tw-m-[4px_0] tw-block tw-text-left tw-text-[14px] tw-font-semibold"
						>
							{displayValue}
						</p>
					)}
					<MobileFilterSelectIcon className="tw-ml-[30px] tw-h-[7px] tw-w-[11px] tw-text-[#606E79]" />
				</div>
			</div>
			{isOpen && (
				<div
					id="optionsContainer"
					role="listbox"
					aria-label={title}
					aria-expanded="true"
					className="tw-fixed tw-bottom-0 tw-left-0 tw-flex tw-w-full tw-flex-col tw-rounded-t-[10px] tw-bg-white tw-p-[25px] tw-transition-all"
				>
					<p className="tw-my-[4px] tw-block tw-text-center tw-font-[AvenirNextLTPro-Regular] tw-text-[15px] tw-font-semibold tw-leading-[1.5] tw-text-[#333]">
						{title}
					</p>
					{options.map((option, idx) => (
						<button
							key={`${option.value}-${idx}`}
							role="option"
							value={option.value}
							onClick={handleChange}
							className="tw-m-[5px_5px_5px_0] tw-min-w-[75px] tw-rounded-[5px] tw-border-none tw-bg-[#e4e4e4] tw-p-[8px] tw-font-[AvenirNextLTPro-Regular] tw-text-[13px] tw-font-semibold tw-leading-[1.4375em] tw-text-[#111] hover:tw-bg-[#1d8ce0] hover:tw-text-white"
						>
							{option.label}
						</button>
					))}
				</div>
			)}
		</>
	);
};

MobileFilterSelect.propTypes = {
	filteringSelect: PropTypes.shape({
		title: PropTypes.string.isRequired,
		filteringKey: PropTypes.string.isRequired,
		options: PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.string.isRequired,
				label: PropTypes.string.isRequired,
			}),
		).isRequired,
	}).isRequired,
};

const DesktopFilterSelect = ({ filteringSelect, placeholders }) => {
	const { title, filteringKey, options } = filteringSelect;
	const { selectedOption, handleChange } = useDesktopFilterSelect(
		filteringSelect,
		placeholders,
	);

	return (
		<div className="tw-relative tw-flex tw-w-full tw-items-center tw-justify-between tw-rounded-[3px] tw-border tw-border-solid tw-border-[#d6dbde] tw-pb-[6px] tw-pl-[48px] tw-pr-[6px] tw-pt-[5px]">
			<label
				htmlFor={`${filteringKey}_Input`}
				className="tw-mb-0 tw-mr-[15px] tw-whitespace-nowrap tw-font-[AvenirNextLTPro-Demi] tw-text-[14px] tw-font-semibold tw-leading-[1.4375em] tw-tracking-[0.00938em] tw-text-black before:tw-absolute before:tw-left-[12px] before:tw-top-[6px] before:tw-h-[26px] before:tw-w-[26px] before:tw-bg-[url('/public/react-images/thecreditreview/creditScoreIcon.svg')] before:tw-bg-no-repeat"
			>
				{title}
			</label>
			<div className="tw-relative tw-inline-flex">
				<select
					className="tw-box-content tw-h-[1.4375em] tw-w-full tw-cursor-pointer tw-appearance-none tw-border-none tw-bg-none tw-pb-[5px] tw-pl-0 tw-pr-[24px] tw-pt-[4px] tw-font-[AvenirNextLTPro-Demi] tw-text-[14px] tw-tracking-[0.00938em] tw-text-black"
					onChange={handleChange}
					value={selectedOption}
					id={`${filteringKey}_Input`}
					data-filtering-key={filteringKey}
				>
					<option value="default" disabled>
						{placeholders.DEFAULT[filteringKey]?.label}
					</option>
					{options.map((option, optionIdx) => (
						<option key={`${option.value}-${optionIdx}`} value={option.value}>
							{option.label}
						</option>
					))}
				</select>
				<NativeSelectDropdownIcon className="tw-pointer-events-none tw-absolute tw-right-0 tw-top-[calc(50%-0.5em)] tw-text-2xl tw-text-[#1d8ce0]" />
			</div>
		</div>
	);
};

DesktopFilterSelect.propTypes = {
	filteringSelect: PropTypes.shape({
		title: PropTypes.string.isRequired,
		filteringKey: PropTypes.string.isRequired,
		options: PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.string.isRequired,
				label: PropTypes.string.isRequired,
			}),
		).isRequired,
	}).isRequired,
	placeholders: PropTypes.object.isRequired,
};
