import { useContext } from "react";
import { twMerge } from "@/helpers/twMerge";
import PropTypes from "prop-types";

import { constructOutboundUrl } from "../../helpers/constructOutboundUrl";
import { useOutboundPaths } from "../../links/useOutboundPaths";
import { CompanyContext } from "../CompanyList/CompanyContext";
import OutboundLink from "../general/OutboundLink";

export const BaseLinkWrapper = ({
	children,
	extraQueryParams,
	className,
	css,
}) => {
	const { company } = useContext(CompanyContext);
	const classNameProp = twMerge(className);
	const { companyVisitPath } = useOutboundPaths();

	const outboundUrl = constructOutboundUrl(companyVisitPath, extraQueryParams);

	return (
		<OutboundLink
			to={outboundUrl}
			company={company}
			className={classNameProp}
			css={css}
		>
			{children}
		</OutboundLink>
	);
};

export default BaseLinkWrapper;

BaseLinkWrapper.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	className: PropTypes.string,
	css: PropTypes.object,
	extraQueryParams: PropTypes.object,
};
